import { Stack } from '@mui/material';
import { UserRole, useUpdateUser } from 'api/users';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { TextField } from 'component/base/TextField';
import { components } from 'openapi-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ChangeUserRoleDialogProps {
  readonly user: components['schemas']['User'];
  readonly onClose: () => void;
}

interface ChangeUserRoleFormData {
  role: Exclude<UserRole, 'account_owner'>;
}

export const ChangeUserRoleDialog = ({ user, onClose }: ChangeUserRoleDialogProps) => {
  const { t } = useTranslation();
  const updateUser = useUpdateUser();

  const existingRole = user.roles?.[0] || 'account_user';

  const methods = useForm<ChangeUserRoleFormData>({
    defaultValues: {
      role: existingRole as Exclude<UserRole, 'account_owner'>,
    },
  });

  const handleConfirm = async (data: ChangeUserRoleFormData) => {
    if (existingRole === 'account_owner') {
      throw new Error('Cannot change account owner role');
    }
    if (data.role !== existingRole) {
      await updateUser.mutateAsync({ userId: user.id!, data: { roles: [data.role] } });
    }
    onClose();
  };

  return (
    <ConfirmationDialog
      open
      action="confirm"
      onConfirm={methods.handleSubmit(handleConfirm)}
      description={
        <FormProvider {...methods}>
          <Stack component="form" spacing={2} onSubmit={methods.handleSubmit(handleConfirm)}>
            <TextField
              label={t('role')}
              name="role"
              options={[
                { value: 'account_user', label: t('account_user') },
                { value: 'billing_admin', label: t('billing_admin') },
                { value: 'user_admin', label: t('user_admin') },
              ]}
              rules={{ required: t('required') }}
            />
          </Stack>
        </FormProvider>
      }
      onClose={onClose}
      title={t('change_user_role')}
    />
  );
};
