import { forwardRef, ReactElement, Ref, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Grid,
  Slide,
  Fade,
  Typography,
  Stack,
  useTheme,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField } from 'component/base/TextField';
import { components } from 'openapi-types';
import { useInviteUser, UserRole } from 'api/users';
import { ProgressiveButton } from 'component/base/ProgressiveButton';

type InviteUserFormData = Omit<components['schemas']['CreateUserRequest'], 'roles'> & {
  role: NonNullable<components['schemas']['CreateUserRequest']['roles']>[number];
};

const Transition = forwardRef(function Transition(
  {
    children,
    ...rest
  }: TransitionProps & {
    readonly children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Fade in appear timeout={2000} ref={ref} {...rest}>
        <Box sx={{ height: '100%' }}>
          <Slide direction="up" timeout={2000} {...rest}>
            {children}
          </Slide>
        </Box>
      </Fade>
    </Box>
  );
});

export default function InviteUser() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useInviteUser();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm<InviteUserFormData>({
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      twofactor_required: false,
      role: 'account_user',
    },
  });
  const require2fa = methods.watch('twofactor_required');
  const theme = useTheme();

  const onSubmit = async (data: InviteUserFormData) => {
    await mutateAsync({
      ...data,
      roles: [data.role],
    });
    reset();
    handleClose();
  };

  const { handleSubmit, reset } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Button
          color="primary"
          variant="contained"
          sx={{
            height: '40px',
            whiteSpace: 'nowrap',
          }}
          onClick={handleClickOpen}
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {t('invite_user')}
        </Button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>
            <Stack spacing={1} direction="row" alignItems="center">
              <Box>
                <FontAwesomeIcon color={theme.palette.grey[600]} size="xl" icon={faUserPlus} />
              </Box>
              <Box>
                <Typography fontWeight="bold">{t('invite_user')}</Typography>
                <Typography>{t('invite_user_instructions')}</Typography>
              </Box>
            </Stack>
            <Box>
              <IconButton onClick={handleClose} color="primary" size="large">
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{t('invite_user_caption')}</DialogContentText>
            <Grid container spacing={3} mt={1} mb={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  defaultValue=""
                  name="firstname"
                  label={t('first_name')}
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  defaultValue=""
                  name="lastname"
                  label={t('last_name')}
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  defaultValue=""
                  name="email"
                  label={t('email')}
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="role"
                  label={t('role')}
                  options={[
                    { value: 'account_user', label: t('account_user') },
                    { value: 'billing_admin', label: t('billing_admin') },
                    { value: 'user_admin', label: t('user_admin') },
                  ]}
                  fullWidth
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{
                alignItems: 'flex-start',
                marginLeft: '0px',
              }}
              control={
                <Switch
                  color="primary"
                  name="twofactor_required"
                  onChange={(_event: any, checked: boolean) =>
                    methods.setValue('twofactor_required', checked)
                  }
                  checked={require2fa}
                />
              }
              label={
                <Box ml={1}>
                  <Typography fontWeight="bold">{t('force_2factor_auth')}</Typography>
                  <Typography>{t('force_2factor_auth_warning')}</Typography>
                </Box>
              }
            />
          </DialogContent>
          <DialogActions>
            <ProgressiveButton
              onClick={async () => {
                await handleSubmit(onSubmit)();
              }}
              isLoading={isPending}
              text={t('invite_user')}
            />
            <Box p={2} pr={1}>
              {t('or')}
            </Box>
            <Button onClick={handleClose}>{t('cancel')}</Button>
          </DialogActions>
        </Dialog>
      </form>
    </FormProvider>
  );
}
